.skillcoy{
  margin-left: -30px;
}
  .anak{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    /* background: tomato; */
    width: 75vw; height: 75vh;
  }
  
  .bungkus{
    position:relative;
      margin: auto;
  }
  .kartu{
      background: #e2f6f7;
      margin-right: 20px;
      border-radius: 15px;
      height: 75vh;
  }
  /* just aesthetics */
  body{
    height: 100vh;
    font-family: 'Muli',sans-serif;
    display:flex;
    overflow: hidden;
    background: #aa4b6b;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }
  .body{
    height: 100vh;
    width: 100vw;
    font-family: 'Muli',sans-serif;
    display:flex;
    overflow: hidden;
  }
  .bungkus-ava{
    border: 1px solid #fff;

    position: relative;
    height: 200px;
    width: 200px;
    border-radius: 50px;
    background: #e2f6f7;
    box-shadow:  20px 20px 60px #c0d1d2, 
                 -20px -20px 60px #ffffff;

                }
  .bungkus-ava-lv2{
    border: 1px solid #fff;

    height: 180px;
    width: 180px;
    border-radius: 50px;
    background: #e2f6f7;
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
background: #e2f6f7;
box-shadow: inset 20px 20px 60px #c0d1d2, 
            inset -20px -20px 60px #ffffff;
   
  }
.bungkus-ava-lv2 img{
  height: 160px;
  width: 160px;
  border-radius: 50px;
  position: Absolute;
  top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    
  
}
.nama{
  margin-top: 25px;
  text-align: center;
  
}
.atas-tempel{
  margin-left:-30px;
  margin-top: 10px;
}
.tempel-atas{
  margin-top:10px;
}

.icon{
width: 4em;
height: 4em;
}
.header-icon{
  width: 3em;
  height: 3em;
  float: left;
  margin-right: 10px;
  align-items: center;
}
.icon-skill{
  width: 3em;
  height: 3em;
}

.bhi{
  margin-left: 50px;

}
.icon-text{
  position: relative;
  height: 50px;
}
.tempelY{
  position: absolute;
  top: 50%;
  -ms-transform: translateY( -50%);
  transform: translateY(-50%);
}

.icon-text h5{
  margin-left: 3em;
}
.bungkus-icon{
  /* align-items: center;
  align-self: middle; */
  border: 1px solid #fff;
  margin-right: 5px;
  float:left;
  position: relative;
  height: 55px;
  width: 55px;
  border-radius: 12px;
background: #e2f6f7;
box-shadow:  6px 6px 12px #c0d1d2, 
             -6px -6px 12px #ffffff;

}
.bungkus-icon:hover{
  margin-right: 5px;
  margin-top: -10px;
  float:left;
  position: relative;
  height: 55px;
  width: 55px;
  border-radius: 12px;
background: #e2f6f7;
box-shadow:  6px 6px 12px #c0d1d2, 
             -6px -6px 12px #ffffff;

}
.bungkus-icon-lv2:hover{
  box-shadow:  6px 6px 12px #c0d1d2, 
             -6px -6px 12px #ffffff;

}
.bungkus-icon-lv2{
  border: 1px solid #fff;

  height: 45px;
  width: 45px;
  background: #e2f6f7;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: #e2f6f7;
  box-shadow: inset 6px 6px 11px #c0d1d2, 
              inset -6px -6px 11px #ffffff;;
}

.bungkus-icon-lv2 span {
  border-radius: 50px;
  position: Absolute;
  top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.fab{
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sosmed{
  align-items: center;
}
.worknow{
  color: #768587;
}
.quote{
  color: #9ab1b5;
  font-style: italic;
  margin-top: 50px;
  margin-bottom: 50px;

}
.menu{
  margin-left: 10px;
  position: relative;
  float: left;
  margin-right: 10px;
  border: 1px solid #fff;
  height: 50px;
  width: 150px;
  border-radius: 12px;
background: #e2f6f7;
box-shadow:  6px 6px 12px #c0d1d2, 
             -6px -6px 12px #ffffff;

  margin-top:10px;


}
.menu:hover{
  box-shadow: inset 6px 6px 12px #c0d1d2, 
            inset -6px -6px 12px #ffffff;

}
.menu-active{
  box-shadow: inset 6px 6px 12px #c0d1d2, 
  inset -6px -6px 12px #ffffff;

}
.active{
  box-shadow: inset 6px 6px 12px #c0d1d2, 
  inset -6px -6px 12px #ffffff;
}
.menu p{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #3b8d99;
}
.bawahmenu{
  height: 3px;
  width: 50px;
    border: 0; border-top: 3px solid rgb(36, 153, 169);
    margin: 1em 0; padding: 0; 
}
.bawahmenuh5{
  font-weight: bold;
  color: #053c45;
}
.isian{
overflow-y: scroll;
height: 53vh;
  background: #d7eff0;
  overflow-x: auto;
}
.text-about{
  margin-top: 5px;
  color: #768587;
}
.text-hobby{
  margin-top: 50px;
  color: #768587;

}
.kegiatan-sekarang{
  background-color:#e2f6f7 ;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.isian-kegiatan{
margin-left: 20px;
}
.detail-kegiatan{
  color: #9ab1b5;

}
.sekolah-tingkat{
  margin-top:-10px;
  color:#66797b ;
}
.deskripsi-experience{
  color:#66797b ;

}
.tahun-experience{
  color:#81969a ;
  font-size: 13px;

}
.tahun-sekolah {
  font-size: 13px;
  margin-top: -10px;
  color:#81969a ;
}
.atas-hobby{
  position: relative;
}
.tempelX{
  position: absolute;
  left: 50%;
  -ms-transform: translateX( -50%);
  transform: translateX(-50%);
}
.kiri{
  margin-left:60px;
}
.kanan{
  margin-right:60px;
}
.kotak-gambar{
  height: 200px;
  width: 100%;
  /* background-color: black; */
  padding-top: 0 ;


  /* overflow: hidden; */
}
.kotak-work{
  border: 2px solid #5c7070;
  border-radius: 20px;
  height: 350px;
  width: 100px;
  
  padding-left: 0 ;
  padding-right: 0 ;
  overflow: hidden;
}
.kotak-work:hover{
  border: 2px solid #203a3a;
  border-radius: 25px;


}
.gambar-work {
  height: 100%;
   width: 100%;  
   object-fit:cover;
   
   /* margin-top: -70px; */
}
.kotak-gambar:hover::after{
  background-image: linear-gradient(120deg, #39a5b6, #053c45);

}
.kotak-gambar::after{
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  width: 100%;
  background-image: linear-gradient(120deg, #053c45, #053c45);
  opacity: 0.5;
  z-index: 0;

}


.bawahan{
  /* margin-top: 0px; */
  /* margin-top: 50px; */
  /* background-color: black; */
}
.text-card-work{
  margin-top: -120px;
   color: white;
  font-weight: 900;
  z-index: 1;
    position: relative;


}
.ukuran {
  font-size: 15px;
  color: #025e63;
}
.tempat-icons-work{
  font-size: 0.5rem;
}
@media (max-width: 330.98px) { 
  .skillcoy{
    margin-left: 0px;
  }
  .kiri{
    margin-left:0px;
  }
  .kanan{
    margin-right:0px;
  }
  .tempel-atas{
    margin-top: 0px;
  }
  .tengahin{
    position: absolute;
    left: 50%;
    -ms-transform: translateX( -50%);
    transform: translateX(-50%);
  }
  .tempelX{
    position: absolute;
    left: 50%;
    -ms-transform: translateX( -50%);
    transform: translateX(-50%);
  }
  .atas-tempel h5{
    text-align:center;
  }
  .atas-tempel{
    margin-left: 0px;
  }
  .iconresume{
    display: flex;
    justify-content: center;
  }
  .body{
    overflow:auto;
  }
  .penengah{
    display: flex;
    justify-content: center;
  }
  .isian-kegiatan{
    margin-left: 0px;
  }
  .kegiatan-sekarang{
    margin-left: 0px;
  }
.menu{
  height: 20px;
  width: 100px;
  font-size: small;
  padding-top: 110px;
}
.isian{
  height: 100%;
  width: 90%;
}
.kartu{
  height: 100%;
  width: 100%;
  
}
.sosmed{
  padding-bottom: 30px;
}

 }

@media(max-width: 767px){
  .tengahin{
    position: absolute;
    left: 50%;
    -ms-transform: translateX( -50%);
    transform: translateX(-50%);
  }
  .skillcoy{
    margin-left: 0px;
  }
  
  .kiri{
    margin-left:0px;
  }
  .kanan{
    margin-right:-25px;
  }
  .tempel-atas{
    margin-top: 0px;
  }
  .atas-tempel h5{
    text-align:center;
  }
  .tempelX{
    position: absolute;
    left: 50%;
    -ms-transform: translateX( -50%);
    transform: translateX(-50%);
  }
  .header-icon{
    align-items: center;
  }
  .atas-tempel{
    margin-left: 0px;
  }
  .iconresume{
    display: flex !important;
    justify-content: center !important;
  }
  .body{
    overflow: auto;
  }
  .kegiatan-sekarang{
    margin-left: 0px;
  }
  .isian-kegiatan{
    margin-top: 10px;
    margin-left: 0px;
  }
  .sosmed{
    padding-bottom: 30px;
  }
  .menu{
    height: 20px;
  width: 100px;
  font-size: small;
  padding-top: 110px;
  
  }
  .menu-induk{
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .isian{
    height: 100%;
  }
    body{
        overflow: auto;
    }
    .kartu{
      margin-top: 20px;
      height: 100%;
    }
  .quote{
    margin-top: 10px;
  margin-bottom: 10px;
  }
  
  }
  @media (min-width: 768px) and (max-width: 991.98px) { 

    .atas-tempel{
      margin-left: 0px;
  
      }
      .body{
        overflow: auto;
      }
      .kartu{
        margin-top: 20px;
      }
  }
  