
.html-container{
	border: 1px solid white;

	height: 40px;
	width: 100%;
	box-shadow: 5px 5px 20px #c0d1d2, 
	-5px -5px 20px #ffffff;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.html-container h2{
	font-size: 15px;
	margin: 20px 10px 20px;
	color:#0e515c;
}
.html-container .loader{

	height: 30px;
	width: 200px;
	box-shadow: 5px 5px 20px #c0d1d2,
				-5px -5px 20px #ffffff,
			inset	5px 5px 20px #c0d1d2,
			inset	-5px -5px 20px #ffffff;
	border-radius: 40px;
	position: relative;
}
.bungkusan{
	width: 100px;
	overflow: auto;
	margin-right: 10px;
}
.zeros::after{
	content: '';
	background: red;
    width: 100px;
	height: 10px;
	float:right;
}
.html-loader{
	position: absolute;
	left: 0;
	background: #053c45;
	height: 30px;
	border-radius: 40px;
	animation-name: html-anim;
	animation-duration: 4s;
}
.htmlskill{
	width: 40%;

}
.html-container .percent{
	margin: 15px;
	color: #053c45;
}
@keyframes html-anim{
	from{
		width: 0%;
	}
}
/*CSS*/
.css-container{
	height: 80px;
	width: 600px;
	box-shadow: 5px 5px 20px rgb(160,175,184),
				-5px -5px 20px rgba(255,255,255,.5);
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.css-container h2{
	margin: 20px 10px 20px;
	color:#41abc8;
}
.css-container .loader{
	height: 30px;
	width: 400px;
	box-shadow: 5px 5px 20px rgb(160,175,184),
				-5px -5px 20px rgba(255,255,255,.5),
			inset	5px 5px 20px rgb(160,175,184),
			inset	-5px -5px 20px rgba(255,255,255,.5);
	border-radius: 40px;
	position: relative;
}
.css-loader{
	position: absolute;
	left: 0;
	background: #41abc8;
	height: 30px;
	width: 90%;
	border-radius: 40px;
	animation-name: css-anim;
	animation-duration: 4s;
}
.css-container .percent{
	margin: 15px;
	color: #41abc8;
}
@keyframes css-anim{
	from{
		width: 0%;
	}
}

/* JavaScript*/
.js-container{
	height: 80px;
	width: 600px;
	box-shadow: 5px 5px 20px rgb(184,184,160),
				-5px -5px 20px rgba(255,255,255,.5);
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.js-container h2{
	margin: 20px 10px 20px;
	color:#acaf02;
}
.js-container .loader{
	height: 30px;
	width: 400px;
	box-shadow: 5px 5px 20px rgb(184,184,160),
				-5px -5px 20px rgba(255,255,255,.5),
			inset	5px 5px 20px rgb(184,184,160),
			inset	-5px -5px 20px rgba(255,255,255,.5);
	border-radius: 40px;
	position: relative;
}
.js-loader{
	position: absolute;
	left: 0;
	background: #acaf02;
	height: 30px;
	width: 60%;
	border-radius: 40px;
	animation-name: js-anim;
	animation-duration: 4s;
}
.js-container .percent{
	margin: 15px;
	color: #acaf02;
}
@keyframes js-anim{
	from{
		width: 0%;
	}
}